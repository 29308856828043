@import "../def";

table {
  width: 100%;
  border-collapse: collapse;

  th {
    font-family: "Archivo Narrow", Sans-serif;
    font-size: 18px;
    color: $primary;
    font-weight: 400;
  }

  td,
  th {
    padding: 8px 24px;
  }

  td {
    border: 1px solid #eee;
    border-left: 0;
    border-right: 0;
    text-align: center;
    vertical-align: top;
  }

  p {
    margin: 0;
  }

  tr:hover,
  tr:active {
    td {
      background-color: #eee;
      border-bottom-color: #bdbdbd;
    }
  }
}