form {
  fieldset {
    border: 0;
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin: 18px 0;

    &:first-child {
      margin-top: 0;
    }
  }
}