@import "../def";
@import "./table.scss";
@import "./form.scss";

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 1.8;
  color: #4a4a4a;
  touch-action: manipulation;
  min-height: 100vh;
}

html {
  scroll-behavior: unset; // SmoothAnchor smooth scrolls
}

body,
#root {
  display: flex;
  flex-direction: column;
}

textarea,
input:not([type="checkbox"]):not([type="file"]) {
  display: inline-block;
  min-height: 40px;
  font-size: 14px;
  line-height: 1.8;
  padding: 6px 12px;
  background-color: transparent;
  vertical-align: middle;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 3px;
  transition: all 0.3s ease;
  -webkit-appearance: none;
  box-sizing: border-box;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  
  &:focus {
    border-color: #bbb;
    outline: 0;
  }
}

textarea {
  &:not(:disabled) {
    background: #fff;
  }
}

h1,
h2,
h3 {
  font-family: "Archivo Narrow", Sans-serif;
  color: $primary;
}

h2 {
  font-size: 34px;
  font-weight: 300;
}

form {
  text-align: center;
}

.view {
  min-height: calc(100vh - 80px);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border: 1px solid #eee;
  border-left: none;
  border-right: none;
  padding: 24px 0;
  box-sizing: border-box;
  &:nth-of-type(2n + 1) {
    background: #fff;
  }
}

ul {
  list-style: none;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

form {
  margin: 24px 0;
}

.ReactCollapse--collapse {
  transition: height 350ms cubic-bezier(0.33, 1, 0.68, 1);
}

.suspense {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

a {
  cursor: pointer;
  
  &,
  &:hover,
  &:visited {
    color: $primary;
  }

  &:hover {
    opacity: 0.85;
  }
  text-decoration: none;
}