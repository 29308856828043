@import "../../../def";

.indicator {
  position: fixed;
  right: 0;
  bottom: 12px;
  padding: 4px 16px;
  font-size: 16px;
  border-radius: 0 0 4px 4px;
  color: #fafafa;
  transform: rotate(90deg);
  transform-origin: top right;
  pointer-events: none;
  background: $primary;
  z-index: 999;
}